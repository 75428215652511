import { ApolloError, MutationResult } from '@apollo/client';

export enum PREFERENCE_KEYS {
  THEME = 'THEME',
  BOOKMARKS = 'BOOKMARKS',
  SIGNS_TABLE_COLUMNS = 'SIGNS_TABLE_COLUMNS',
  NOTIFICATION_POP_OUT = 'NOTIFICATION_POP_OUT',
  NOTIFICATION_LIST = 'NOTIFICATION_LIST',
  NOTIFICATION_TABLE_FILTERS = 'NOTIFICATION_TABLE_FILTERS',
  DISPLAY_TYPES_TABLE_COLUMNS = 'DISPLAY_TYPES_TABLE_COLUMNS',
  INTEGRATIONS_TABLE_COLUMNS = 'INTEGRATIONS_TABLE_COLUMNS',
  INTEGRATIONS_GROUPS_TABLE_COLUMNS = 'INTEGRATIONS_GROUPS_TABLE_COLUMNS',
  MESSAGE_LIBRARY_TABLE_COLUMNS = 'MESSAGE_LIBRARY_TABLE_COLUMNS',
  ROUTES_TABLE_COLUMNS = 'ROUTES_TABLE_COLUMNS',
  EVENT_TEMPLATES_TABLE_COLUMNS = 'EVENT_TEMPLATES_TABLE_COLUMNS',
  EVENT_MANAGEMENT_TABLE_COLUMNS = 'EVENT_MANAGEMENT_TABLE_COLUMNS',
  EVENT_PLANNING_TABLE_COLUMNS = 'EVENT_PLANNING_TABLE_COLUMNS',
  EVENT_SIGN_RULE_TABLE_COLUMNS = 'EVENT_SIGN_RULE_TABLE_COLUMNS',
  EVENT_TEMPLATE_TABLE_COLUMNS = 'EVENT_TEMPLATE_TABLE_COLUMNS',
  DETECTOR_ALARM_TABLE_COLUMNS = 'DETECTOR_ALARM_TABLE_COLUMNS',
  FLEET_VEHICLE_TABLE_COLUMNS = 'FLEET_VEHICLE_TABLE_COLUMNS',
  FLEET_VEHICLE_TABLE_FILTERS = 'FLEET_VEHICLE_TABLE_FILTERS',
  FLEET_DRIVER_TABLE_COLUMNS = 'FLEET_DRIVER_TABLE_COLUMNS',
  FLEET_DRIVER_TABLE_FILTERS = 'FLEET_DRIVER_TABLE_FILTERS',
  ROAD_ASSISTANCE_TABLE_COLUMNS = 'ROAD_ASSISTANCE_TABLE_COLUMNS',
  ROAD_ASSISTANCE_TABLE_FILTERS = 'ROAD_ASSISTANCE_TABLE_FILTERS',
  ROAD_ASSISTANCE_ROUTE_TABLE_COLUMNS = 'ROAD_ASSISTANCE_ROUTE_TABLE_COLUMNS',
  ROAD_ASSISTANCE_ROUTE_TABLE_FILTERS = 'ROAD_ASSISTANCE_ROUTE_TABLE_FILTERS',
  ROAD_CONDITION_EVENT_TABLE_COLUMNS = 'ROAD_CONDITION_EVENT_TABLE_COLUMNS',
  ROAD_CONDITION_EVENT_TABLE_FILTERS = 'ROAD_CONDITION_EVENT_TABLE_FILTERS',
  ROAD_ASSISTANCE_TABLE_VIEW = 'ROAD_ASSISTANCE_TABLE_VIEW',
  PARKING_AREA_TABLE_COLUMNS = 'PARKING_AREA_TABLE_COLUMNS',
  PARKING_AREA_TABLE_FILTERS = 'PARKING_AREA_TABLE_FILTERS',
  GATE_CONTROLLER_TABLE_COLUMNS = 'GATE_CONTROLLER_TABLE_COLUMNS',
  GATE_CONTROLLER_TABLE_FILTERS = 'GATE_CONTROLLER_TABLE_FILTERS',
  GATE_SIGN_MESSAGE_TABLE_COLUMNS = 'GATE_SIGN_MESSAGE_TABLE_COLUMNS',
  GATE_SIGN_MESSAGE_TABLE_FILTERS = 'GATE_SIGN_MESSAGE_TABLE_FILTERS',
  GATE_SYSTEM_TABLE_COLUMNS = 'GATE_SYSTEM_TABLE_COLUMNS',
  GATE_SYSTEM_TABLE_FILTERS = 'GATE_SYSTEM_TABLE_FILTERS',
  GATE_SYSTEM_CONTROLLER_TABLE_COLUMNS = 'GATE_SYSTEM_CONTROLLER_TABLE_COLUMNS',
  GATE_SYSTEM_CONTROLLER_TABLE_FILTERS = 'GATE_SYSTEM_CONTROLLER_TABLE_FILTERS',
  GATE_CONTROLLER_SIGN_TABLE_COLUMNS = 'GATE_CONTROLLER_SIGN_TABLE_COLUMNS',
  GATE_CONTROLLER_ARM_TABLE_COLUMNS = 'GATE_CONTROLLER_ARM_TABLE_COLUMNS',
  CORRIDOR_WEATHER_TABLE_COLUMNS = 'CORRIDOR_WEATHER_TABLE_COLUMNS',
  CORRIDOR_WEATHER_TABLE_FILTERS = 'CORRIDOR_WEATHER_TABLE_FILTERS',
  CORRIDOR_DETECTION_TABLE_COLUMNS = 'CORRIDOR_DETECTION_TABLE_COLUMNS',
  CORRIDOR_DETECTION_TABLE_FILTERS = 'CORRIDOR_DETECTION_TABLE_FILTERS',
  CORRIDOR_TABLE_COLUMNS = 'CORRIDOR_TABLE_COLUMNS',
  CORRIDOR_TABLE_FILTERS = 'CORRIDOR_TABLE_FILTERS',
  CORRIDOR_EVENT_TABLE_COLUMNS = 'CORRIDOR_EVENT_TABLE_COLUMNS',
  CORRIDOR_EVENT_TABLE_FILTERS = 'CORRIDOR_EVENT_TABLE_FILTERS',
  CORRIDOR_CAMERA_TABLE_FILTERS = 'CORRIDOR_CAMERA_TABLE_FILTERS',
  CORRIDOR_TRAVEL_TIME_TABLE_COLUMNS = 'CORRIDOR_TRAVEL_TIME_TABLE_COLUMNS',
  CORRIDOR_TRAVEL_TIME_TABLE_FILTERS = 'CORRIDOR_TRAVEL_TIME_TABLE_FILTERS',
  CORRIDOR_DETECTOR_TABLE_COLUMNS = 'CORRIDOR_DETECTOR_TABLE_COLUMNS',
  CORRIDOR_DETECTOR_TABLE_FILTERS = 'CORRIDOR_DETECTOR_TABLE_FILTERS',
  CORRIDOR_SIGN_TABLE_COLUMNS = 'CORRIDOR_SIGN_TABLE_COLUMNS',
  CORRIDOR_SIGN_TABLE_FILTERS = 'CORRIDOR_SIGN_TABLE_FILTERS',
  SITE_TABLE_COLUMNS = 'SITE_TABLE_COLUMNS',
  CUTOUT_TABLE_COLUMNS = 'CUTOUT_TABLE_COLUMNS',
  DETECTOR_SITE_REALTIME_TABLE_COLUMNS = 'DETECTOR_SITE_REALTIME_TABLE_COLUMNS',
  DETECTOR_SITE_ACTIVITY_LOG_TABLE_COLUMNS = 'DETECTOR_SITE_ACTIVITY_LOG_TABLE_COLUMNS',
  DETECTOR_SITE_REALTIME_TABLE_FILTERS = 'DETECTOR_SITE_REALTIME_TABLE_FILTERS',
  SIGNS_MULTI_POST_COLUMNS = 'SIGNS_MULTI_POST_COLUMNS',
  TRAVEL_TIME_TABLE_COLUMNS = 'TRAVEL_TIME_TABLE_COLUMNS',
  TRAVEL_TIME_TABLE_FILTERS = 'TRAVEL_TIME_TABLE_FILTERS',
  INTERSECTIONS_TABLE_COLUMNS = 'INTERSECTIONS_TABLE_COLUMNS',
  INTERSECTIONS_TABLE_FILTERS = 'INTERSECTIONS_TABLE_FILTERS',
  LOCATIONS_TABLE_COLUMNS = 'LOCATIONS_TABLE_COLUMNS',
  LOCATIONS_TABLE_FILTERS = 'LOCATIONS_TABLE_FILTERS',
  LOCATION_DOCUMENTS_TABLE_COLUMNS = 'LOCATION_DOCUMENTS_TABLE_COLUMNS',
  LOCATION_DOCUMENTS_TABLE_FILTERS = 'LOCATION_DOCUMENTS_TABLE_FILTERS',
  GANTRY_TABLE_COLUMNS = 'GANTRY_TABLE_COLUMNS',
  SPEED_SETTINGS_TABLE_COLUMNS = 'SPEED_SETTINGS_TABLE_COLUMNS',
  WEATHER_HISTORY_TABLE_COLUMNS = 'WEATHER_HISTORY_TABLE_COLUMNS',
  WEATHER_STATION_TABLE_COLUMNS = 'WEATHER_STATION_TABLE_COLUMNS',
  SYSTEM_ROLES_TABLE_COLUMNS = 'SYSTEM_ROLES_TABLE_COLUMNS',
  SYSTEM_USER_GROUPS_TABLE_COLUMNS = 'SYSTEM_USER_GROUPS_TABLE_COLUMNS',
  SYSTEM_USERS_TABLE_COLUMNS = 'SYSTEM_USERS_TABLE_COLUMNS',
  SYSTEM_MAP_CONFIG_COLUMNS = 'SYSTEM_MAP_CONFIG_COLUMNS',
  SYSTEM_MAP_LAYER_CONFIG_COLUMNS = 'SYSTEM_MAP_LAYER_CONFIG_COLUMNS',
  LANE_CONTROL_SETTINGS_COLUMNS = 'LANE_CONTROL_SETTINGS_CONFIG_COLUMNS',
  SYSTEM_FORM_CONFIG_COLUMNS = 'SYSTEM_FORM_CONFIG_COLUMNS',
  CONTACTS_TABLE_COLUMNS = 'CONTACTS_TABLE_COLUMNS',
  ORGANIZATIONS_TABLE_COLUMNS = 'ORGANIZATIONS_TABLE_COLUMNS',
  REQUEST_ACTIVITY_LOG_TABLE_COLUMNS = 'REQUEST_ACTIVITY_LOG_TABLE_COLUMNS',
  EMAIL_REQUEST_ACTIVITY_LOG_TABLE_FILTERS = 'EMAIL_REQUEST_ACTIVITY_LOG_TABLE_FILTERS',
  RULE_ACTIVITY_LOG_TABLE_COLUMNS = 'RULE_ACTIVITY_LOG_TABLE_COLUMNS',
  EMAIL_RULE_ACTIVITY_LOG_TABLE_FILTERS = 'EMAIL_RULE_ACTIVITY_LOG_TABLE_FILTERS',
  DEFAULT_ORGANIZATION = 'DEFAULT_ORGANIZATION',
  EXTENT_LAYERS = 'EXTENT_LAYERS',
  WORKSPACES = 'WORKSPACES',
  SYSTEM_WORKSPACES = 'SYSTEM_WORKSPACES',
  EVENT_DASHBOARD_FILTERS = 'EVENT_DASHBOARD_FILTERS',
  UNIT = 'UNIT',
  DATE_FORMAT = 'DATE_FORMAT',
  TIME_FORMAT = 'TIME_FORMAT',
  DETECTOR_CONNECTION_LOG_TABLE_COLUMNS = 'DETECTOR_CONNECTION_LOG_TABLE_COLUMNS',
  RECORDINGS_TABLE_COLUMNS = 'RECORDINGS_TABLE_COLUMNS',
  ACTIVITY_LOG_TABLE_COLUMNS = 'ACTIVITY_LOG_TABLE_COLUMNS',
  INTEGRATIONS_TABLE_FILTERS = 'INTEGRATIONS_TABLE_FILTERS',
  ALARMS_TABLE_FILTERS = 'ALARMS_TABLE_FILTERS',
  DETECTORS_TABLE_FILTERS = 'DETECTORS_TABLE_FILTERS',
  CAMERAS_TABLE_FILTERS = 'CAMERAS_TABLE_FILTERS',
  RECORDINGS_TABLE_FILTERS = 'RECORDINGS_TABLE_FILTERS',
  ACTIVITY_LOG_TABLE_FILTERS = 'ACTIVITY_LOG_TABLE_FILTERS',
  WEATHER_STATION_TABLE_FILTERS = 'WEATHER_STATION_TABLE_FILTERS',
  EVENTS_PLANNING_TABLE_FILTERS = 'EVENTS_PLANNING_TABLE_FILTERS',
  EVENTS_MANAGEMENT_TABLE_FILTERS = 'EVENTS_MANAGEMENT_TABLE_FILTERS',
  EVENT_TEMPLATES_TABLE_FILTERS = 'EVENT_TEMPLATES_TABLE_FILTERS',
  CORRIDOR_LOG_TABLE_FILTERS = 'CORRIDOR_LOG_TABLE_FILTERS',
  CORRIDOR_LOG_TABLE_COLUMNS = 'CORRIDOR_LOG_TABLE_COLUMNS',
  EVENTS_DETECTION_TABLE_FILTERS = 'EVENTS_DETECTION_TABLE_FILTERS',
  EVENTS_DETECTION_TABLE_COLUMNS = 'EVENTS_DETECTION_TABLE_COLUMNS',
  EVENTS_DETECTION_TABLE_VIEW = 'EVENTS_DETECTION_TABLE_VIEW',
  EVENT_RULE_TABLE_FILTERS = 'EVENT_RULE_TABLE_FILTERS',
  SIGNALS_INTERSECTIONS_TABLE_FILTERS = 'SIGNALS_INTERSECTIONS_TABLE_FILTERS',
  SIGNS_TABLE_FILTERS = 'SIGNS_TABLE_FILTERS',
  MESSAGE_LIBRARY_TABLE_FILTERS = 'MESSAGE_LIBRARY_TABLE_FILTERS',
  CONTACTS_TABLE_FILTERS = 'CONTACTS_TABLE_FILTERS',
  TUNNEL_TABLE_COLUMNS = 'TUNNEL_TABLE_COLUMNS',
  TUNNEL_TABLE_FILTERS = 'TUNNEL_TABLE_FILTERS',
  TUNNEL_LOG_TABLE_COLUMNS = 'TUNNEL_LOG_TABLE_COLUMNS',
  RESOURCE_PLAN_TABLE_COLUMNS = 'RESOURCE_PLAN_TABLE_COLUMNS',
  RESOURCE_PLAN_SIGN_TABLE_COLUMNS = 'RESOURCE_PLAN_SIGN_TABLE_COLUMNS',
  RESOURCE_PLAN_SIGN_TABLE_FILTERS = 'RESOURCE_PLAN_SIGN_TABLE_FILTERS',
  RESOURCE_PLAN_SPEED_LIMIT_TABLE_COLUMNS = 'RESOURCE_PLAN_SPEED_LIMIT_TABLE_COLUMNS',
  RESOURCE_PLAN_SPEED_LIMIT_TABLE_FILTERS = 'RESOURCE_PLAN_SPEED_LIMIT_TABLE_FILTERS',
  RESOURCE_PLAN_LANE_CONTROL_TABLE_COLUMNS = 'RESOURCE_PLAN_LANE_CONTROL_TABLE_COLUMNS',
  RESOURCE_PLAN_LANE_CONTROL_TABLE_FILTERS = 'RESOURCE_PLAN_LANE_CONTROL_TABLE_FILTERS',
  WEATHER_ATMOSPHERIC_TABLE_COLUMNS = 'WEATHER_ATMOSPHERIC_TABLE_COLUMNS',
  WEATHER_PAVEMENT_TABLE_COLUMNS = 'WEATHER_PAVEMENT_TABLE_COLUMNS',
  WEATHER_SUBSURFACE_TABLE_COLUMNS = 'WEATHER_SUBSURFACE_TABLE_COLUMNS',
  EMAIL_RULES_TABLE_FILTERS = 'EMAIL_RULES_TABLE_FILTERS',
  EMAIL_RULES_TABLE_COLUMNS = 'EMAIL_RULES_TABLE_COLUMNS',
  CAMERA_ACTIVITY_LOG_TABLE_COLUMNS = 'CAMERA_ACTIVITY_LOG_TABLE_COLUMNS',
  CAMERA_ACTIVITY_LOG_TABLE_FILTERS = 'CAMERA_ACTIVITY_LOG_TABLE_FILTERS',
  COMMUNICATION_LOGS_TABLE_COLUMNS = 'CAMERA_ACTIVITY_LOG_TABLE_COLUMNS',
  COMMUNICATION_LOGS_TABLE_FILTERS = 'CAMERA_ACTIVITY_LOG_TABLE_FILTERS',
  MAP_STATUS_BAR = 'MAP_STATUS_BAR',
  VIDEO_WALL_DEFAULT_SCENE = 'VIDEO_WALL_DEFAULT_SCENE',
  PATROL_ROUTES_TABLE_FILTERS = 'PATROL_ROUTES_TABLE_FILTERS',
  MAP_CLUSTERING = 'MAP_CLUSTERING',
  MAP_ZOOM_BUTTONS = 'MAP_ZOOM_BUTTONS',
  VOLUME_WIDGET_TABLE_COLUMNS = 'VOLUME_WIDGET_TABLE_COLUMNS',
  VOLUME_WIDGET_TABLE_FILTERS = 'VOLUME_WIDGET_TABLE_FILTERS',
  EVENT_ACTIVITY_LOG_TABLE_COLUMNS = 'EVENT_ACTIVITY_LOG_TABLE_COLUMNS',
  EVENT_ACTIVITY_LOG_TABLE_FILTERS = 'EVENT_ACTIVITY_LOG_TABLE_FILTERS',
  DEVICE_EVENTS_TABLE_FILTERS = 'DEVICE_EVENTS_TABLE_FILTERS',
  DEVICE_EVENTS_TABLE_COLUMNS = 'DEVICE_EVENTS_TABLE_COLUMNS',
  APPLIED_COMMANDS_TABLE_FILTERS = 'APPLIED_COMMANDS_TABLE_FILTERS',
  APPLIED_COMMANDS_TABLE_COLUMNS = 'APPLIED_COMMANDS_TABLE_COLUMNS',
  COUNTS_RULES_TABLE_FILTERS = 'COUNTS_RULES_TABLE_FILTERS',
  COUNTS_ACTIVITY_LOG_TABLE_FILTERS = 'COUNTS_ACTIVITY_LOG_TABLE_FILTERS',
  COUNTS_SITE_GROUPS_TABLE_FILTERS = 'COUNTS_SITE_GROUPS_TABLE_FILTERS',
  COUNTS_SITES_TABLE_FILTERS = 'COUNTS_SITES_TABLE_FILTERS',
  COUNTS_TURNING_REPORT_TABLE_FILTERS = 'COUNTS_TURNING_REPORT_TABLE_FILTERS',
  COUNTS_VEHICLEGROSSCOUNTWEIGHT_WIDGET_TABLE_COLUMNS = 'COUNTS_VEHICLEGROSSCOUNTWEIGHT_WIDGET_TABLE_COLUMNS',
  COUNTS_VEHICLEGROSSCOUNTWEIGHT_WIDGET_TABLE_FILTERS = 'COUNTS_VEHICLEGROSSCOUNTWEIGHT_WIDGET_TABLE_FILTERS',
  COUNTS_VEHICLEAXLECOUNT_WIDGET_TABLE_FILTERS = 'COUNTS_VEHICLEAXLECOUNT_WIDGET_TABLE_FILTERS',
  COUNTS_VEHICLEAXLECOUNT_WIDGET_TABLE_COLUMNS = 'COUNTS_VEHICLEAXLECOUNT_WIDGET_TABLE_COLUMNS',
  ASSET_MANAGEMENT_INVENTORY_TABLE_FILTERS = 'ASSET_MANAGEMENT_INVENTORY_TABLE_FILTERS',
  ASSET_MANAGEMENT_INVENTORY_TABLE_COLUMNS = 'ASSET_MANAGEMENT_INVENTORY_TABLE_COLUMNS',
  USER_AVATAR = 'USER_AVATAR',
  PERFORMANCE_REPORT_WIDGET_TABLE_COLUMNS = 'PERFORMANCE_REPORT_WIDGET_TABLE_COLUMNS',
  EVENT_REVIEW_TABLE_COLUMNS = 'EVENT_REVIEW_TABLE_COLUMNS',
  EVENT_REVIEW_TABLE_FILTERS = 'EVENT_REVIEW_TABLE_FILTERS',
  EVENT_REVIEW_LIST = 'EVENT_REVIEW_LIST',
  NOTIFICATION_TABLE_COLUMNS = 'NOTIFICATION_TABLE_COLUMNS',
  SPEED_WIDGET_TABLE_COLUMNS = 'SPEED_WIDGET_TABLE_COLUMNS',
  SPEED_WIDGET_TABLE_COLUMNS_FILTERS = 'SPEED_WIDGET_TABLE_COLUMNS_FILTERS',
  SIGN_MULTI_POST_MESSAGE_TABLE_COLUMNS = 'SIGN_MULTI_POST_MESSAGE_TABLE_COLUMNS',
  SIGN_MULTI_POST_MESSAGE_TABLE_FILTERS = 'SIGN_MULTI_POST_MESSAGE_TABLE_FILTERS',
  SIGN_MESSAGE_TABLE_COLUMNS = 'SIGN_MESSAGE_TABLE_COLUMNS',
  SIGN_MESSAGE_TABLE_FILTERS = 'SIGN_MESSAGE_TABLE_FILTERS',
  SIGN_HISTORY_TABLE_COLUMNS = 'SIGN_HISTORY_MESSAGE_TABLE_COLUMNS',
  SIGN_HISTORY_TABLE_FILTERS = 'SIGN_HISTORY_MESSAGE_TABLE_FILTERS',
  SIGN_LIBRARY_TABLE_COLUMNS = 'SIGN_LIBRARY_TABLE_COLUMNS',
  SIGN_LIBRARY_TABLE_FILTERS = 'SIGN_LIBRARY_TABLE_FILTERS',
  SIGN_GROUP_TABLE_COLUMNS = 'SIGN_GROUP_TABLE_COLUMNS',
  SIGN_GROUP_TABLE_FILTERS = 'SIGN_GROUP_TABLE_FILTERS',
  SIGN_ALARMS_TABLE_COLUMNS = 'SIGN_ALARMS_TABLE_COLUMNS',
  SIGN_ALARMS_TABLE_FILTERS = 'SIGN_ALARMS_TABLE_FILTERS',
  SIGN_STATUS_LOG_TABLE_COLUMNS = 'SIGN_STATUS_LOG_TABLE_COLUMNS',
  SIGN_STATUS_LOG_TABLE_FILTERS = 'SIGN_STATUS_LOG_TABLE_FILTERS',
  SIGN_LOG_TABLE_COLUMNS = 'SIGN_LOG_TABLE_COLUMNS',
  SIGN_LOG_TABLE_FILTERS = 'SIGN_LOG_TABLE_FILTERS',
  SIGN_RULE_TABLE_COLUMNS = 'SIGN_RULE_TABLE_COLUMNS',
  SIGN_RULE_TABLE_FILTERS = 'SIGN_RULE_TABLE_FILTERS',
  EVENT_SIGN_RESPONSE_TABLE_COLUMNS = 'EVENT_SIGN_RESPONSE_TABLE_COLUMNS',
  EVENT_SIGN_RESPONSE_TABLE_FILTERS = 'EVENT_SIGN_RESPONSE_TABLE_FILTERS',
  SIGNAL_CORRIDORS_TABLE_COLUMNS = 'SIGNAL_CORRIDORS_TABLE_COLUMNS',
  SIGNAL_CORRIDORS_TABLE_FILTERS = 'SIGNAL_CORRIDORS_TABLE_FILTERS',
  SIGNAL_ACTION_SETS_TABLE_COLUMNS = 'SIGNAL_ACTION_SETS_TABLE_COLUMNS',
  SIGNAL_ACTION_SETS_TABLE_FILTERS = 'SIGNAL_ACTION_SETS_TABLE_FILTERS',
  MAP_SEARCH_HISTORY = 'MAP_SEARCH_HISTORYS',
  MAP_ACCESS_TOKEN = 'MAP_ACCESS_TOKEN',
  SIGNAL_CORRIDORS_LEGS_TABLE_COLUMNS = 'SIGNAL_CORRIDORS_LEGS_TABLE_COLUMNS',
  SIGNAL_CORRIDORS_LEGS_TABLE_FILTERS = 'SIGNAL_CORRIDORS_LEGS_TABLE_FILTERS',
  SIGNAL_CORRIDORS_LINKS_TABLE_COLUMNS = 'SIGNAL_CORRIDORS_LINKS_TABLE_COLUMNS',
  SIGNAL_CORRIDORS_LINKS_TABLE_FILTERS = 'SIGNAL_CORRIDORS_LINKS_TABLE_FILTERS',
  INTERSECTION_TEMPLATE_TABLE_COLUMNS = 'INTERSECTION_TEMPLATE_TABLE_COLUMNS',
  INTERSECTION_TEMPLATE_TABLE_FILTERS = 'INTERSECTION_TEMPLATE_TABLE_FILTERS',
  SIGNAL_ACTIVITY_LOG_TABLE_COLUMNS = 'SIGNAL_ACTIVITY_LOG_TABLE_COLUMNS',
  SIGNAL_ACTIVITY_LOG_TABLE_FILTERS = 'SIGNAL_ACTIVITY_LOG_TABLE_FILTERS',
  ASSOCIATE_EVENTS_DETECTION_TABLE_FILTERS = 'ASSOCIATE_EVENTS_DETECTION_TABLE_FILTERS',
  ASSOCIATED_EVENTS_DETECTIONS_TABLE_FILTERS = 'ASSOCIATED_EVENTS_DETECTIONS_TABLE_FILTERS',
  TRAVEL_TIME_ACTIVITY_LOG_TABLE_COLUMNS = 'TRAVEL_TIME_ACTIVITY_LOG_TABLE_COLUMNS',
  TRAVEL_TIME_ACTIVITY_LOG_TABLE_FILTERS = 'TRAVEL_TIME_ACTIVITY_LOG_TABLE_FILTERS',
  TRAVEL_TIME_DETAILS_ACTIVITY_LOG_TABLE_FILTERS = 'TRAVEL_TIME_DETAILS_ACTIVITY_LOG_TABLE_FILTERS',
  WEATHER_STATIONS_ACTIVITY_LOG_TABLE_COLUMNS = 'WEATHER_STATIONS_ACTIVITY_LOG_TABLE_COLUMNS',
  WEATHER_STATIONS_ACTIVITY_LOG_TABLE_FILTERS = 'WEATHER_STATIONS_ACTIVITY_LOG_TABLE_FILTERS',
  WEATHER_STATIONS_DETAILS_ACTIVITY_LOG_TABLE_FILTERS = 'WEATHER_STATIONS_DETAILS_ACTIVITY_LOG_TABLE_FILTERS',
  SYSTEM_ACTIVITY_LOG_TABLE_COLUMNS = 'SYSTEM_ACTIVITY_LOG_TABLE_COLUMNS',
  SYSTEM_ACTIVITY_LOG_TABLE_FILTERS = 'SYSTEM_ACTIVITY_LOG_TABLE_FILTERS',
  SIGNAL_ALARMS_TABLE_COLUMNS = 'SIGNAL_ALARMS_TABLE_COLUMNS',
  SIGNAL_ALARMS_TABLE_FILTERS = 'SIGNAL_ALARMS_TABLE_FILTERS',
  ASSOCIATE_EVENTS_COMM_LOGS_TABLE_FILTERS = 'ASSOCIATE_EVENTS_COMM_LOGS_TABLE_FILTERS',
  TIMEZONE = 'TIMEZONE',
  TIME_SPACE_DIAGRAM_CONFIGS = 'TIME_SPACE_DIAGRAM_CONFIGS',
  SIGNAL_DETECTOR_GROUPS_TABLE_COLUMNS = 'SIGNAL_DETECTOR_GROUPS_TABLE_COLUMNS',
  SIGNAL_DETECTOR_GROUPS_TABLE_FILTERS = 'SIGNAL_DETECTOR_GROUPS_TABLE_FILTERS',
  SIGNAL_TRAFFIC_RESPONSIVE_PLANS_TABLE_COLUMNS = 'SIGNAL_TRAFFIC_RESPONSIVE_PLANS_TABLE_COLUMNS',
  SIGNAL_TRAFFIC_RESPONSIVE_PLANS_TABLE_FILTERS = 'SIGNAL_TRAFFIC_RESPONSIVE_PLANS_TABLE_FILTERS',
  SIGNAL_INTERSECTION_DETAIL_ACTIVITY_LOG_TABLE_COLUMNS = 'SIGNAL_INTERSECTION_DETAIL_ACTIVITY_LOG_TABLE_COLUMNS',
  SIGNAL_INTERSECTION_DETAIL_ACTIVITY_LOG_TABLE_FILTERS = 'SIGNAL_INTERSECTION_DETAIL_ACTIVITY_LOG_TABLE_FILTERS',
  MAP_CONFIG_SESSION_KEY = 'mapConfig',
  SCHEDULED_EVENTS_TABLE_COLUMNS = 'SCHEDULED_EVENTS_TABLE_COLUMNS',
  SCHEDULED_EVENTS_TABLE_FILTERS = 'SCHEDULED_EVENTS_TABLE_FILTERS',
  SIGNAL_DATABASE_CHANGE_LOGS_TABLE_COLUMNS = 'SIGNAL_DATABASE_CHANGE_LOGS_TABLE_COLUMNS',
  SIGNAL_DATABASE_CHANGE_LOGS_TABLE_FILTERS = 'SIGNAL_DATABASE_CHANGE_LOGS_TABLE_FILTERS',
  HISTORICAL_ALARMS_REPORT_COLUMNS = 'HISTORICAL_ALARMS_REPORT_COLUMNS',
  HISTORICAL_ALARMS_REPORT_FILTERS = 'HISTORICAL_ALARMS_REPORT_FILTERS',
  DATABASE_EXPORT_TEMPLATES_COLUMNS = 'DATABASE_EXPORT_TEMPLATES_COLUMNS',
  TRAFFIC_RESPONSIVE_APPLIED_COMMANDS_FILTERS = 'TRAFFIC_RESPONSIVE_APPLIED_COMMANDS_FILTERS',
  TRAFFIC_RESPONSIVE_APPLIED_COMMANDS_COLUMNS = 'TRAFFIC_RESPONSIVE_APPLIED_COMMANDS_COLUMNS',
  ACTION_CONTROL_LOG_FILTERS = 'ACTION_CONTROL_LOG_FILTERS',
  ACTION_CONTROL_LOG_COLUMNS = 'ACTION_CONTROL_LOG_COLUMNS',
  DATABASE_EXPORT_TEMPLATES_FILTERS = 'DATABASE_EXPORT_TEMPLATES_FILTERS',
  JOYSTICK_HOTKEYS = 'JOYSTICK_HOTKEYS',
  JOYSTICK_HOTKEYS_MAPPING = 'JOYSTICK_HOTKEYS_MAPPING',
  CONNECTED_VEHICLES_LIST_TABLE_COLUMNS = 'CONNECTED_VEHICLES_LIST_TABLE_COLUMNS',
  CONNECTED_VEHICLES_LIST_TABLE_FILTERS = 'CONNECTED_VEHICLES_LIST_TABLE_FILTERS',
  CONNECTED_VEHICLES_ALARMS_TABLE_COLUMNS = 'CONNECTED_VEHICLES_ALARMS_TABLE_COLUMNS',
  CONNECTED_VEHICLES_ALARMS_TABLE_FILTERS = 'CONNECTED_VEHICLES_ALARMS_TABLE_FILTERS',
  CONNECTED_VEHICLES_ACTIVITY_LOG_TABLE_COLUMNS = 'CONNECTED_VEHICLES_ACTIVITY_LOG_TABLE_COLUMNS',
  CONNECTED_VEHICLES_ACTIVITY_LOG_TABLE_FILTERS = 'CONNECTED_VEHICLES_ACTIVITY_LOG_TABLE_FILTERS',
  CONNECTED_VEHICLES_DETAILS_ACTIVITY_LOG_TABLE_COLUMNS = 'CONNECTED_VEHICLES_DETAILS_ACTIVITY_LOG_TABLE_COLUMNS',
  CONNECTED_VEHICLES_DETAILS_ACTIVITY_LOG_TABLE_FILTERS = 'CONNECTED_VEHICLES_DETAILS_ACTIVITY_LOG_TABLE_FILTERS',
  ASSET_MANAGEMENT_WORK_ORDER_TABLE_COLUMNS = 'ASSET_MANAGEMENT_WORK_ORDER_TABLE_COLUMNS',
  ASSET_MANAGEMENT_WORK_ORDER_TABLE_FILTERS = 'ASSET_MANAGEMENT_WORK_ORDER_TABLE_FILTERS',
}

export interface PreferenceState<T> {
  key: string;
  loading: boolean;
  preference: T | undefined;
  error: ApolloError | undefined;
}

export interface PreferenceMutation<T> {
  savePreference: (value: T) => Promise<any>;
  clearPreference: () => void;
  savePreferenceResult?: MutationResult<any>;
}

export type usePreferenceData<T> = [PreferenceState<T>, PreferenceMutation<T>];
